
@font-face {
  font-family: 'Compass Sans';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url("//d11k51v32u8ru4.cloudfront.net/fonts/2.5.0/CompassSans-Regular.woff2") format('woff2'),
       url("//d11k51v32u8ru4.cloudfront.net/fonts/2.5.0/CompassSans-Regular.woff") format('woff'),
       url("//d11k51v32u8ru4.cloudfront.net/fonts/2.5.0/CompassSans-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'Compass Sans';
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: url("//d11k51v32u8ru4.cloudfront.net/fonts/2.5.0/CompassSans-Medium.woff2") format('woff2'),
       url("//d11k51v32u8ru4.cloudfront.net/fonts/2.5.0/CompassSans-Medium.woff") format('woff'),
       url("//d11k51v32u8ru4.cloudfront.net/fonts/2.5.0/CompassSans-Medium.ttf") format('truetype');
}

@font-face {
  font-family: 'Compass Sans';
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url("//d11k51v32u8ru4.cloudfront.net/fonts/2.5.0/CompassSans-Bold.woff2") format('woff2'),
       url("//d11k51v32u8ru4.cloudfront.net/fonts/2.5.0/CompassSans-Bold.woff") format('woff'),
       url("//d11k51v32u8ru4.cloudfront.net/fonts/2.5.0/CompassSans-Bold.ttf") format('truetype');
}

@font-face {
  font-family: 'Compass Serif';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url("//d11k51v32u8ru4.cloudfront.net/fonts/2.5.0/CompassSerif-Regular.woff2") format('woff2'),
       url("//d11k51v32u8ru4.cloudfront.net/fonts/2.5.0/CompassSerif-Regular.woff") format('woff'),
       url("//d11k51v32u8ru4.cloudfront.net/fonts/2.5.0/CompassSerif-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'Compass Serif';
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: url("//d11k51v32u8ru4.cloudfront.net/fonts/2.5.0/CompassSerif-SemiBold.woff2") format('woff2'),
       url("//d11k51v32u8ru4.cloudfront.net/fonts/2.5.0/CompassSerif-SemiBold.woff") format('woff'),
       url("//d11k51v32u8ru4.cloudfront.net/fonts/2.5.0/CompassSerif-SemiBold.ttf") format('truetype');
}

@font-face {
  font-family: 'Compass Serif';
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url("//d11k51v32u8ru4.cloudfront.net/fonts/2.5.0/CompassSerif-Bold.woff2") format('woff2'),
       url("//d11k51v32u8ru4.cloudfront.net/fonts/2.5.0/CompassSerif-Bold.woff") format('woff'),
       url("//d11k51v32u8ru4.cloudfront.net/fonts/2.5.0/CompassSerif-Bold.ttf") format('truetype');
}

:root {
  --font-weightRegular: 400;
  --font-weightMedium: 500;
  --font-weightBold: 700;
  --font-weightSemiBold: 600;
}

.font-sansRegular {
  font-family: "Compass Sans", Helvetica, Arial, sans-serif;
  font-weight: var(--font-weightRegular, 400);
  font-style: normal;
}

.font-sansMedium {
  font-family: "Compass Sans", Helvetica, Arial, sans-serif;
  font-weight: var(--font-weightMedium, 500);
  font-style: normal;
}

.font-sansBold {
  font-family: "Compass Sans", Helvetica, Arial, sans-serif;
  font-weight: var(--font-weightBold, 700);
  font-style: normal;
}

.font-serifRegular {
  font-family: "Compass Serif", Times, "Times New Roman", serif;
  font-weight: var(--font-weightRegular, 400);
  font-style: normal;
}

.font-serifSemiBold {
  font-family: "Compass Serif", Times, "Times New Roman", serif;
  font-weight: var(--font-weightSemiBold, 600);
  font-style: normal;
}

.font-serifBold {
  font-family: "Compass Serif", Times, "Times New Roman", serif;
  font-weight: var(--font-weightBold, 700);
  font-style: normal;
}


body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}